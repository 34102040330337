import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { Input, Button, Checkbox, Tag, Space } from 'antd';
import {performAction, validatePayload} from '../Actions';
import { Table } from 'antd';
import { openNotificationWithIcon } from 'utils/auth';
import { PUBLISHED_VERSION, ARCHIVED_VERSION, DRAFT_VERSION} from '../global_constants';

function Validation() {

  const [validators, setValidators] = useState([])
  const [ruleSets, setRuleSets] = useState([])
  const [fieldSets, setFieldSets] = useState([])
  const [menuTypes, setMenuTypes] = useState([])
  const [entityTypes, setEntityTypes] = useState([]);

  const [showResult, setShowResult] = useState(false);
  const [refresh, setRefresh] = useState(false)

  // track form elements
  const [validatorIdentifier, setValidatorIdentifier] = useState()
  const [payload, setPayload] = useState({})
  const [include_draft_rules, setIncludeDraftRules] = useState(false)

  const [constraints, setConstraints] = useState([])

  const onValidatorSelection = ({target:{value}}) => setValidatorIdentifier(value)
  const onPayloadChange = ({target:{value}}) => setPayload(value)

  const color_map = {
    [PUBLISHED_VERSION]: "green",
    [DRAFT_VERSION]: "blue",
    [ARCHIVED_VERSION]: "grey"
  }

  const handleSubmit =  async (e) => {
    e.preventDefault()
    let final_payload = {
        "validator": validatorIdentifier,
        "payload": payload,
        "include_draft_rules": include_draft_rules
      }
    const validation_resp = await validatePayload(final_payload)
    if (validation_resp.success) {
      let values = Object.values(validation_resp.data)
      setConstraints(values)
      setShowResult(true)
      setRefresh(true)
    } else {
      openNotificationWithIcon('error', validation_resp.msg)
    }
  }

  useEffect(() => {
    async function fetch_validators() {
      let validators_list = await performAction('validator', 'list')
      setValidators(validators_list)
    }
    fetch_validators()
    setRefresh(false)
}, [refresh])

    useEffect(() => {
        async function fetch_entity_types() {
            let entities = await performAction('entity-type', 'list')
            setEntityTypes(entities)
        }
        fetch_entity_types()
    }, [])

    const getRow = (rule, index) => {
        return   {
          key: index,
          id: rule.rule_info.id,
          entityType: rule,
          rule: rule.rule_info.error_message,
          status: rule.status,
          failedEntities: rule,
        }
    }

    const getFailedEntityColumnText = (rule) => {

      if(rule.rule_info.rule_kind === "custom"){
        return rule.faulty_entities.map((faultyEntity, idx) => {
          return <p key={idx} style={{marginBottom:"5px"}}>{faultyEntity["ref_id"]}</p>
      })} else {
        // Fetch unique Ref Ids of failed entities
        // from inside conditions list of simple rule
        let refIds = new Set()
        let conditions = rule.rule_info.conditions
        if(conditions){
          Object.values(conditions).forEach((condition) => {
            condition.faulty_entities && condition.faulty_entities.forEach((faultyEntity) => {
              refIds.add(faultyEntity["ref_id"])
            })
          })
          return Array.from(refIds).map((refId, idx) => {
            return <p key={idx} style={{marginBottom:"5px"}}>{refId}</p>
          })
        }

      }
    }

    const getEntityColumn = (rule) => {
      if(rule.rule_info.rule_kind === "simple"){
          if(rule.rule_info.conditions){
            return Object.values(rule.rule_info.conditions).map((condition) => {
                return <p>{condition.entity_identifier}</p>
            })
          }
      } else {
          return <p>{rule.rule_info.entity_identifier}</p>
      }
  }

    const filterEntityForSimpleRule = (value, record) => {
      let found = false
      if(record.entityType.rule_info.conditions){
          Object.values(record.entityType.rule_info.conditions).forEach((condition) => {
              if(condition.entity_identifier.includes(value)){
                  found = true
                  return
              }
          })
      }
      return found
  }

    const columns = [
      {
        "title": 'UID',
        "dataIndex": 'id',
        "key": 'id',
      },
      {
        "title": "Entity Type",
        "dataIndex": "entityType",
        "key": "entityType",
        "filters": entityTypes.map((entityType) => {return {"text": entityType.identifier, "value": entityType.identifier}}),
        "onFilter": (value, record) => record.entityType.rule_info.rule_kind === 'custom' ? 
        record.entityType.rule_info.entity_identifier.includes(value) : filterEntityForSimpleRule(value, record),
        "render": (rule) => getEntityColumn(rule),
      },
      {
        "title": "Rule",
        "dataIndex": "rule",
        "key": "rule",
      },
      {
        "title": "Status",
        "dataIndex": "status",
        "key": "status",
      },
      {
        "title": "Failed Entities",
        "dataIndex": "failedEntities",
        "key": "failedEntities",
        "render": row => getFailedEntityColumnText(row)
      },
      {
        "title": "Rule Status",
        "dataIndex": "state",
        "key": "state",
        "render": (rule, record) => {
          const status = record.entityType.rule_info.status
          return (
              <Space>
                  <Tag color={color_map[status] || "red"}>{status.toUpperCase()}</Tag>
                  <Tag color="blue">{"v" + record.entityType.rule_info.version}</Tag>
              </Space>
              )
        }
      }
    ]
    const onChange = (e) => {
      setIncludeDraftRules(e.target.checked)
    };

    const data = constraints.map(getRow)

      return (
        <>
        <Row>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", paddingBottom:"20px"}}>
            <h4>Enter Menu Payload</h4>
            <Form.Group>
              <Form.Select onChange={onValidatorSelection}>
                <option>Select Validator</option>
                {validators.map((validator, idx) => {
                    return <option value={validator.identifier}>{validator.identifier}</option>
                })}
              </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Validate
            </Button>
          </div>
          <Form>
            <Form.Group controlId="payloadField">
                <Form.Control as="textarea" rows="10" name="payload" onChange={onPayloadChange}/>
                {/*value={JSON.stringify(menu, null, '    ')}*/}
            </Form.Group>
          </Form>
          <Checkbox style={{marginTop:"10px", marginLeft:"10px"}} onChange={onChange} checked={include_draft_rules}>Include rules in <b>DRAFT</b> state</Checkbox>
        </Row>
        <Row style={{paddingTop:"20px", paddingLeft:"12px"}}>
        {showResult &&
          <Table columns={columns} dataSource={data}/>
          }
          </Row>
        </>
      )
    }

export default Validation
